<template>
  <div id="mian">
    <div class="header">
      <span id="fzcolor">基本信息</span>
      <span @click="toUrl('ledger_detail_jsxx', { id: $route.query.id })">结算信息</span>
      <span @click="toUrl('ledger_detail_glqd', { id: $route.query.id })">关联渠道</span>
      <span @click="toUrl('ledger_detail_dzzb', { id: $route.query.id })">电子账簿</span>
      <span @click="toUrl('ledger_detail_cpxx', { id: $route.query.id })">产品信息</span>
      <span @click="toUrl('ledger_detail_bccl', { id: $route.query.id })">补充材料</span>
    </div>
    <div class="desc">
        <h5 class="jsxx">基本信息</h5>
    </div>
    <div class="input_con" style="margin-top:20px;">
      <ul class="inputBox">
        <li>
          <span> <i>*</i>商户名称 </span>
          <el-input
            class="inputs"
            v-model="formData.mchName"
            maxlength="128"
            placeholder="输入商户名称"
            tabindex="1"
          ></el-input>
        </li>
        <li>
          <span> <i>*</i>商户类型 </span>
          <el-select
            class="inputs"
            v-model="formData.mchType"
            placeholder="选择商户类型"
            tabindex="4"
          >
            <el-option
              v-for="(v, i) in mchType"
              :key="i"
              :label="v.title"
              :value="v.value"
            ></el-option>
          </el-select>
        </li>
        <li>
          <span> <i>*</i>行业类别 </span>
          <el-select
            class="inputs"
            v-model="formData.bussinessType"
            placeholder="选择行业类别"
            tabindex="4"
          >
            <el-option
              v-for="(v, i) in industrysType"
              :key="i"
              :label="v.name"
              :value="v.id"
            ></el-option>
          </el-select>
        </li>
        <li>
          <span> <i>*</i>营业执照编号 </span>
          <el-input
            class="inputs"
            maxlength="128"
            v-model="formData.licenceNo"
            placeholder="输入营业执照编号"
            tabindex="6"
          ></el-input>
        </li>
        <li>
          <span> <i>*</i>营业执照注册地址 </span>
          <el-input
            class="inputs"
            maxlength="128"
            v-model="formData.companyAddress"
            placeholder="输入营业执照注册地址"
            tabindex="7"
          ></el-input>
        </li>
        <li>
          <span> <i>*</i>营业执照有效期</span>
          <div class="inputLine">
            <el-date-picker
              class="inputs pick"
              v-model="formData.licenceBegin"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="开始日期"
            >
            </el-date-picker>
            <span style="padding-left: 10px; padding-right: 10px">至</span>
            <el-date-picker
              class="inputs pick"
              v-model="formData.licenceEnd"
              :picker-options="licenceOptions"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="结束日期"
            >
            </el-date-picker>
          </div>
        </li>
        <li>
          <span><i>*</i>经营地址</span>
          <!-- inputLineCity -->
          <div class="inputLine">
            <el-select
              class="inputs citys"
              v-model="formData.registryProvince"
              @change="getAddress(1)"
              placeholder="选择省"
              tabindex="10"
            >
              <el-option
                v-for="(v, i) in provinceOption"
                :key="i"
                :label="v.province"
                :value="v.provinceCode"
              ></el-option>
            </el-select>
            <el-select
              class="inputs citys"
              v-model="formData.registryCity"
              @change="getAddress(2)"
              placeholder="选择市"
              tabindex="11"
            >
              <el-option
                v-for="(v, i) in cityOption"
                :key="i"
                :label="v.city"
                :value="v.cityCode"
              ></el-option>
            </el-select>
            <el-select
              class="inputs citys"
              v-model="formData.registryDistrict"
              placeholder="选择区"
              tabindex="12"
            >
              <el-option
                v-for="(v, i) in countyOption"
                :key="i"
                :label="v.county"
                :value="v.countyCode"
              ></el-option>
            </el-select>
          </div>
        </li>
        <li>
          <span> <i>*</i>经营详细地址 </span>
          <el-input
            class="inputs"
            maxlength="128"
            v-model="formData.registryAddress"
            placeholder="输入经营详细地址"
            tabindex="7"
          ></el-input>
        </li>
        <li>
          <span> <i>*</i>法人姓名 </span>
          <el-input
            class="inputs"
            v-model="formData.legalPersonName"
            maxlength="128"
            placeholder="输入法人姓名"
            tabindex="13"
          ></el-input>
        </li>
        <li>
          <span> <i>*</i>法人手机号码 </span>
          <el-input
            class="inputs"
            v-model="formData.legalPersonPhone"
            maxlength="11"
            placeholder="输入手机号码"
            tabindex="8"
          ></el-input>
        </li>
        <li>
          <span> <i>*</i>法人身份证号码 </span>
          <el-input
            class="inputs"
            v-model="formData.idcardNo"
            maxlength="128"
            placeholder="输入法人身份证号码"
            tabindex="14"
          ></el-input>
        </li>
        <li>
          <span>法人证件有效期</span>
          <div class="inputLine">
            <el-date-picker
              class="inputs pick"
              v-model="formData.legalPersonCemStartDate"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="开始日期"
            >
            </el-date-picker>
            <span style="padding-left: 10px; padding-right: 10px">至</span>
            <el-date-picker
              class="inputs pick"
              v-model="formData.legalPersonCemEndDate"
              :picker-options="PersonOptions"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="结束日期"
            >
            </el-date-picker>
          </div>
        </li>
        <li>
          <span> <i v-show="formData.mchType=='ENTERPRISE'">*</i>开户许可证编号 </span> <!--企业必填-->
          <el-input
            class="inputs"
            maxlength="128"
            v-model="formData.accountOpeningLicenceNo"
            placeholder="输入开户许可证编号"
            tabindex="11"
          ></el-input>
        </li>
      </ul>
    </div>
    <div class="upload_ph">
      <div class="uploader1">
        <h4><i>*</i>营业执照照片</h4>
        <el-upload
          class="avatar-uploader"
          :before-upload="beforeUpload"
          drag
          :action="uploadUrl"
          :show-file-list="false"
          :on-success="licencePictureSuccess"
        >
          <img
            v-if="formData.licencePicture"
            :src="formData.licencePicture"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          <span v-if="formData.licencePicture" class="avatar-uploader-span" @click.stop="formData.licencePicture = ''"><i class="el-icon-close"></i></span>
        </el-upload>
      </div>
      <div class="uploader1">
        <h4><i>*</i>法人身份证国徽照片</h4>
        <el-upload
          class="avatar-uploader"
          :before-upload="beforeUpload"
          drag
          :action="uploadUrl"
          :show-file-list="false"
          :on-success="idcardNormalPictureSuccess"
        >
          <img
            v-if="formData.idcardNormalPicture"
            :src="formData.idcardNormalPicture"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          <span v-if="formData.idcardNormalPicture" class="avatar-uploader-span" data-val="idcardNormalPicture" @click.stop="formData.idcardNormalPicture=''"><i class="el-icon-close"></i></span>
        </el-upload>
      </div>
      <div class="uploader1">
        <h4><i>*</i>法人身份证人像照片</h4>
        <el-upload
          class="avatar-uploader"
          :before-upload="beforeUpload"
          drag
          :action="uploadUrl"
          :show-file-list="false"
          :on-success="idcardReversePictureSuccess"
        >
          <img
            v-if="formData.idcardReversePicture"
            :src="formData.idcardReversePicture"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          <span v-if="formData.idcardReversePicture" class="avatar-uploader-span"  @click.stop="formData.idcardReversePicture=''"><i class="el-icon-close"></i></span>
        </el-upload>
      </div>
      <div class="uploader1">
        <h4><i>*</i>开户许可证照片</h4>
        <el-upload @click.stop
          class="avatar-uploader"
          :before-upload="beforeUpload"
          drag
          :action="uploadUrl"
          :show-file-list="false"
          :on-success="accountOpeningLicenseSuccess"
        >
          <img
            v-if="formData.accountOpeningLicense"
            :src="formData.accountOpeningLicense"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          <span v-if="formData.accountOpeningLicense" class="avatar-uploader-span" @click.stop="formData.accountOpeningLicense=''"><i class="el-icon-close"></i></span>
        </el-upload>
      </div>
    </div>
    <div class="bottom">
      <el-button class="back" type="primary" @click="$router.go(-1)"
        >返回</el-button
      >
      <el-button @click="onSubmit" class="next" type="primary">保存</el-button>
    </div>
    <div class="cover_view" v-if="showImg !== ''" @click="showImg = ''">
      <a :href="showImg" target="_blank">
        <img :src="showImg" alt="加载失败" />
      </a>
    </div>
  </div>
</template>
<script>
import { URL_API } from "@/utils/apiUrl.js";
import { ledgerDetail, setledger } from "@/api/user/shopManager.js";
import * as imageConversion from "image-conversion";
import industry from "@/utils/industry.json";
import address from "@/utils/address.json";
var legalPersonCemEndDate = null,
licenceEnd = null;
export default {
  data() {
    return {
      isImge: true, //判断商户协议上传的是不是图片
      formData: {
        mchName: "", //商户名称
        mchType: "", //商户类型，
        bussinessType:"",//行业类别
        licenceNo:"",//营业执照编号
        companyAddress:"",//营业执照注册地址
        licenceBegin:"", //营业执照有效期
        licenceEnd:"", //营业执照有效期
        legalPersonName:"",//法人姓名
        legalPersonPhone:"",//法人手机号码
        idcardNo:'',//法人身份证号码
        legalPersonCemStartDate:"",//法人证件有效期
        legalPersonCemEndDate:"",//法人证件有效期
        // contactTel:"",//联系号码
        // contactEmail:"",//联系邮箱
        accountOpeningLicenceNo:"",//开户许可证编号
        contactName:"",//取值-法人姓名

        registryProvince:"",//省
        registryCity:"",//市
        registryDistrict:"",//区
        registryAddress:"",//详细地址

        licencePicture: "", //营业执照照片URL
        idcardNormalPicture: "", //	true	身份证国徽照片（法人或联系人）
        idcardReversePicture: "", //	true	身份证人像照片（法人或联系人
        accountOpeningLicense: "", //开户许可证图片URL true
      },
      oldFormData: {}, //没编辑前的详情数据,用来跟编辑后的对比
      mchType: [
        {
          title: "企业",
          value: "ENTERPRISE",
        },
        {
          title: "个体工商户",
          value: "INDIVIDUAL_BUSINESS",
        },
      ],
      PersonOptions: {
        shortcuts: [
          {
            text: "长期",
            onClick(picker) {
              picker.$emit("pick", "2999-01-01");
              legalPersonCemEndDate = "forever";
            },
          },
        ],
      },
      licenceOptions: {
        shortcuts: [
          {
            text: "长期",
            onClick(picker) {
              picker.$emit("pick", "2999-01-01");
              licenceEnd = "forever";
            },
          },
        ],
      },
      uploadUrl: URL_API.upload,
      showImg: "",
      industrysType:industry,
      provinceOption: address,//地区
      cityOption: [],
      countyOption: [],
    };
  },
  created() {
    this.getDetail();
  },
  methods: {
    // 获取详情
    getDetail() {
      ledgerDetail(this.$route.query.id).then((res) => {
        if (res) {
          this.formData = res.resultData.mchDetail;
          this.oldFormData = res.resultData.mchDetail;
          if (this.formData.legalPersonCemEndDate == "forever") {
            legalPersonCemEndDate = "forever";
            this.formData.legalPersonCemEndDate = "2999-01-01";
          }
          if (this.formData.licenceEnd == "forever") {
            licenceEnd = "forever";
            this.formData.licenceEnd = "2999-01-01";
          }
          this.getAddress();
        }
      });
    },
    // 获取地址
    getAddress(active = 0) {
      if (active == 1) {
        this.formData.registryCity = "";
        this.formData.registryDistrict = "";
        this.cityOption = this.provinceOption.find(
          (v) => v.provinceCode == this.formData.registryProvince
        ).citys;
      } else if (active == 2) {
        this.formData.registryDistrict = "";
        this.countyOption = this.cityOption.find(
          (v) => v.cityCode == this.formData.registryCity
        ).countys;
      } else {
        if (this.formData.registryProvince) {
          this.cityOption = this.provinceOption.find(
            (v) => v.provinceCode == this.formData.registryProvince
          ).citys;
        }
        if (this.formData.registryCity) {
          this.countyOption = this.cityOption.find(
            (v) => v.cityCode == this.formData.registryCity
          ).countys; 
        }
      }     
    },
    // 营业执照照片
    licencePictureSuccess(res) {
      if (this.isImge && res.resultStatus) {
        this.formData.licencePicture = res.resultData.fileLink;
      } else {
        return false;
      }
    },
    // 身份证国徽照片
    idcardNormalPictureSuccess(res) {
      if (this.isImge && res.resultStatus) {
        this.formData.idcardNormalPicture = res.resultData.fileLink;
      } else {
        return false;
      }
    },
    // 身份证人像照片
    idcardReversePictureSuccess(res) {
      if (this.isImge && res.resultStatus) {
        this.formData.idcardReversePicture = res.resultData.fileLink;
      } else {
        return false;
      }
    },
    // 开户许可证上传
    accountOpeningLicenseSuccess(res) {
      if (this.isImge && res.resultStatus) {
        this.formData.accountOpeningLicense = res.resultData.fileLink;
      } else {
        return false;
      }
    },
    // 表单规则
    roles() {
      this.formData.contactName = this.formData.legalPersonName;
      let formData = JSON.parse(JSON.stringify(this.formData));
      if (!formData.mchName) {
        this.$message.error("商户名称必填");
        return false;
      }
      if (!formData.mchType) {
        this.$message.error("商户类型必选");
        return false;
      }
      if(!formData.bussinessType){
        this.$message.error("行业类别必选");
        return false;
      }
      if(!formData.licenceNo){
        this.$message.error("营业执照编号必填");
        return false;
      }
      if(!formData.companyAddress){
        this.$message.error("营业执照注册地址必填");
        return false;
      }
      if (!formData.licenceBegin) {
          this.$message.error("营业执照开始日期必选");
          return false;
      }
      if (!formData.licenceEnd) {
          this.$message.error("营业执照结束日期必选");
          return false;
      }
      if (!formData.registryProvince) {
          this.$message.error("省份必填");
          return false;
      }
      if (!formData.registryCity) {
          this.$message.error("城市必填");
          return false;
      }
      if (!formData.registryDistrict) {
          this.$message.error("区县必填");
          return false;
      }
      if (!formData.registryAddress) {
          this.$message.error("经营详细地址必填");
          return false;
      }       
      if(!formData.legalPersonName){
        this.$message.error("法人姓名必填");
        return false;
      }
      if (!formData.legalPersonPhone) {
        this.$message.error("法人手机号码必填");
        return false;
      } else {
        if (formData.legalPersonPhone.indexOf("*") != -1) {
          console.log("手机号码有星号,判定为未做修改");
        } else {
          if (!/^[1][2,3,4,5,6,7,8,9][0-9]{9}$/.test(formData.legalPersonPhone)) {
            this.$message.error("请正确填写法人手机号码");
            return;
          }
        }
      }      
      if (!formData.idcardNo) {
          this.$message.error("法人身份证号码必填");
          return false;
      } else {
          if (formData.idcardNo.indexOf("*") != -1) {
            // console.log("法人身份证号码有星号,判定为未做修改");
          } else {
            if (
              !/^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(
                formData.idcardNo
              )
            ) {
              this.$message.error("请输入合法的身份证号码");
              return false;
            }
          }
      }   
      if (!formData.legalPersonCemStartDate) {
          this.$message.error("法人证件开始日期必选");
          return false;
      }
      if (!formData.legalPersonCemEndDate) {
          this.$message.error("法人证件结束日期必选");
          return false;
      }     
      if(formData.mchType=='ENTERPRISE'){
        if (!formData.accountOpeningLicenceNo) {
          this.$message.error("开户许可证编号必填");
          return false;
        }
      }
      if (!formData.licencePicture) {
        this.$message.error("营业执照照片必传");
        return false;
      }      
      if (!formData.idcardNormalPicture) {
        this.$message.error("法人身份证国徽照片必传");
        return false;
      }
      if (!formData.idcardReversePicture) {
        this.$message.error("法人身份证人像照片必传");
        return false;
      }
      if (!formData.accountOpeningLicense) {
        this.$message.error("开户许可证照片必传");
        return false;
      }

      if (formData.legalPersonCemEndDate == "2999-01-01") {
        formData.legalPersonCemEndDate = "forever";
      }
      if (formData.licenceEnd == "2999-01-01") {
        formData.licenceEnd = "forever";
      }

      sessionStorage.setItem("mchDetailFWS", JSON.stringify(formData));
      return true;
    },
    // 修改
    onSubmit() {
      if (!this.roles()) {
        return;
      }
      let data = JSON.parse(sessionStorage.getItem("mchDetailFWS"));
      console.log("编辑后提交的商户基本信息数据", data);

      setledger(this.$route.query.id, data).then((res) => {
        if (res.resultStatus) {
          this.$message.success("保存成功");
          this.$router.go(-1);
        }
      });
    },
    // 页面跳转
    toUrl(name, data) {
      if (!this.roles()) {
        return;
      }
      this.$router.push({
        name: name,
        query: data,
      });
    },
    // 点击展示图片
    handlePreview(e) {
      this.showImg = e.url;
    },
    beforeUpload(file) {
      this.isImge = true;
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension =
        testmsg === "jpg" ||
        testmsg === "JPG" ||
        testmsg === "jpeg" ||
        testmsg === "JPEG" ||
        testmsg === "png" ||
        testmsg === "PNG" ||
        testmsg === "bpm" ||
        testmsg === "BPM";
      console.log("文件上传前", extension);

      if (!extension) {
        this.$message.error("上传图片仅支持jpg/jpeg/png/bpm格式");
        this.isImge = false;
        return;
      }
      // if (!extension) {
      //   return false;
      // }
      return new Promise((resolve) => {
        let isLt2M = file.size / 1024 < 2048; // 判定图片大小是否小于2MB = 2048kb
        if (isLt2M) {
          resolve(file);
        }
        // 压缩到2048KB,这里的2048就是要压缩的大小,可自定义
        imageConversion.compressAccurately(file, 2048).then((res) => {
          resolve(res);
        });
      });
    },
  },
};
</script>
<style scoped>
@import "../../../assets/css/desc.css";
@import "../../../assets/css/editInput.css";
/* .inputLineCity{
  display: flex;
  justify-content: space-between;
}
.inputs.citys {
    width: calc((100% - 40px)/3);
} */
.back {
  width: 120px;
  line-height: 10px;
  height: 32px;
  padding: 0;
  background: rgba(255, 255, 255, 1);
  border-color: #dce0e6;
  border: 1px solid rgba(220, 224, 230, 1);
  border-radius: 3px;
  color: var(--title);
  font-size: 12px;
}

.next {
  width: 120px;
  height: 32px;
  background: var(--themeColor);
  border-color: var(--themeColor);
  border-radius: 3px;
  font-size: 12px;
  padding: 0;
}

.bottom {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 18px;
}
.djsc {
  width: 120px;
  height: 32px;
  background: var(--themeColor);
  border-color: var(--themeColor);
  border-radius: 3px;
}

.upload_wj_right {
  float: left;
  margin-left: 200px;
  width: 22%;
  height: 100%;
}

.upload_wj h5 {
  padding: 29px 0 19px 0;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
}

.upload_wj_left {
  float: left;
  margin-left: 44px;
  width: 22%;
  height: 100%;
}


.upload_wj {
  margin-bottom: 25px;
  width: 100%;
  padding-bottom: 25px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(239, 239, 239, 1);
  border-radius: 4px;
}

.uploader1 {
  margin-left: 30px;
  float: left;
}

.uploader1 h4 {
  height: 52px;
  line-height: 52px;
  font-size: 14px;
  color: #000000;
}

.uploader1 h4 i {
  color: var(--themeColor);
  padding-right: 3px;
}

.uploader2 {
  margin-left: 30px;
  margin-right: 19px;
  float: left;
}

.avatar-uploader {
  width: 120px;
  height: 120px;
  background: rgba(246, 246, 246, 1);
  border: 1px solid rgb(241, 242, 244);
  border-radius: 4px;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}

.avatar {
  width: 120px;
  height: 120px;
  display: block;
}
.upload_ph p {
  padding-top: 20px;
  padding-bottom: 20px;
}

.upload_ph p span i {
  color: var(--themeColor);
  padding-right: 3px;
}

.upload_ph p span {
  font-size: 14px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
}

.upload_ph {
  width: 100%;
  height: 200px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(239, 239, 239, 1);
  border-radius: 4px;
  margin-bottom: 20px;
}
.title {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 40px;
}

.cover_view {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
}

.cover_view img {
  position: absolute;
  min-height: 200px;
  min-width: 200px;
  max-height: 100%;
  max-width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
}
.upload_wj_left i {
  color: var(--themeColor);
  padding-right: 3px;
}
</style>
